import {
  useMutation,
  useQuery,
  useQueryClient,
  type QueryObserverResult,
  type RefetchOptions,
  type UseMutateAsyncFunction,
} from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  type ReactNode,
} from 'react';

import { GlobalLoader } from '@/ui';

export interface AuthProviderConfig<User = unknown> {
  key?: [string];
  loadUser: (data: any) => Promise<User>;
  loginFn: (data: any) => Promise<User>;
  registerFn: (data: any) => Promise<User>;
  sessionLoginFn: () => Promise<unknown>;
  logoutFn: () => Promise<any>;
  waitInitial?: boolean;
  LoaderComponent?: () => JSX.Element;
  ErrorComponent?: ({ error }: { error: AxiosError | null }) => JSX.Element;
}

export interface AuthContextValue<
  User = unknown,
  LoginCredentials = unknown,
  RegisterCredentials = unknown,
> {
  user: User | undefined;
  login: UseMutateAsyncFunction<User, any, LoginCredentials>;
  logout: UseMutateAsyncFunction<any, any, void, any>;
  register: UseMutateAsyncFunction<User, any, RegisterCredentials>;
  isLoggingIn: boolean;
  isLoggingOut: boolean;
  isRegistering: boolean;
  refetchUser: (
    options?: RefetchOptions | undefined,
  ) => Promise<QueryObserverResult<User, AxiosError>>;
  error: AxiosError | null;
}

export interface AuthProviderProps {
  children: ReactNode;
}

export function initReactQueryAuth<
  User = unknown,
  LoginCredentials = unknown,
  RegisterCredentials = unknown,
>(config: AuthProviderConfig<User>) {
  const AuthContext = createContext<AuthContextValue<
    User,
    LoginCredentials,
    RegisterCredentials
  > | null>(null);
  AuthContext.displayName = 'AuthContext';

  const {
    loadUser,
    loginFn,
    registerFn,
    logoutFn,
    sessionLoginFn,
    key = ['auth-user'],
    waitInitial = true,
    LoaderComponent = () => <GlobalLoader hideContentBelow />,
    ErrorComponent = (error: any) => (
      <div style={{ color: 'tomato' }}>{JSON.stringify(error, null, 2)}</div>
    ),
  } = config;

  function AuthProvider({ children }: AuthProviderProps): JSX.Element {
    const queryClient = useQueryClient();

    const sessionLoginMutation = useMutation<unknown, AxiosError>({
      mutationFn: sessionLoginFn,
    });

    const {
      data: user,
      error,
      status,
      isLoading,
      fetchStatus,
      isSuccess,
      refetch,
    } = useQuery<User, AxiosError>({
      queryKey: key,
      queryFn: loadUser,
      retry: false,
      // Access token is not present in the cookie, so we try to login with the PHPSESSID cookie
      onError: async err => {
        if (err.response && err.response.status === 401) {
          sessionLoginMutation.mutate(undefined, {
            // No PHPSESSID found, user needs to login again
            onError: sessionLoginError => {
              if (
                sessionLoginError.response &&
                sessionLoginError.response.status > 300
              ) {
                window.location.replace(
                  `${
                    import.meta.env.VITE_DASHBOARD_APP_URL
                  }/logoff?redirectBack=checkout`,
                );
              }
            },
            // PHPSESSID found and we logged in and got access token
            onSuccess: () => {
              refetch();
            },
          });
        }
      },
    });

    const setUser = useCallback(
      (data: User) => queryClient.setQueryData(key, data),
      [queryClient],
    );

    const loginMutation = useMutation({
      mutationFn: loginFn,
      onSuccess: user => {
        setUser(user);
      },
    });

    const registerMutation = useMutation({
      mutationFn: registerFn,
      onSuccess: user => {
        setUser(user);
      },
    });

    const logoutMutation = useMutation({
      mutationFn: logoutFn,
      onSuccess: () => {
        queryClient.clear();
      },
    });

    const value = useMemo(
      () => ({
        user,
        error,
        refetchUser: refetch,
        login: loginMutation.mutateAsync,
        isLoggingIn: loginMutation.isLoading,
        logout: logoutMutation.mutateAsync,
        isLoggingOut: logoutMutation.isLoading,
        register: registerMutation.mutateAsync,
        isRegistering: registerMutation.isLoading,
      }),
      [
        user,
        error,
        refetch,
        loginMutation.mutateAsync,
        loginMutation.isLoading,
        logoutMutation.mutateAsync,
        logoutMutation.isLoading,
        registerMutation.mutateAsync,
        registerMutation.isLoading,
      ],
    );

    if (isSuccess || !waitInitial) {
      return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
      );
    }

    if (isLoading || fetchStatus === 'idle') {
      return <LoaderComponent />;
    }

    if (error) {
      return <ErrorComponent error={error} />;
    }

    return <div>Unhandled status: {status}</div>;
  }

  function useAuth() {
    const context = useContext(AuthContext);
    if (!context) {
      throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
  }

  return { AuthProvider, AuthConsumer: AuthContext.Consumer, useAuth };
}
